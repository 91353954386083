<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div v-if="guanbi">
        <div class="yanjing" v-if="moren_dakai==0" @click="guanbi=false">
            <div class="box" @click.stop>
                <p class="box_tit">客户相关的归档号</p>
                <ul class="kehu_ul">
                    <li v-for='(i,index) in kehu_guidang_hao_list' :key='index' @click="xuan_num=index">
                        <img :src="xuan_num==index?require('../../assets/me/xuan_zi.png'):require('../../assets/me/weixuan_zi.png')" alt="">---
                        {{i.file_no}}
                    </li>
                </ul>
                <div class="kehu_btn" @click="dianji_dakai_liebioao">确定</div>
            </div>
        </div>
        <div class="yanjing" v-if="moren_dakai==1" @click="moren_dakai=0">
            <div class="box" @click.stop>
                <!-- <i class="el-icon-close" @click="moren_dakai=0"></i> -->
                <p class="box_tit">归档单据查询</p>
                <ul class="ul" v-for="(i,index) in title" :key='index'>
                    <li v-if="i.children.length!=0"  @click="i.zhi=!i.zhi">
                        <p style="cursor: pointer;margin-bottom:0.1rem;">
                            <i style="font-size:0.14rem;" :class="!i.zhi?'el-icon-caret-right':'el-icon-caret-bottom'"></i>
                            {{i.name}}<span class="changdu">({{i.children.length}})</span>
                        </p>
                        <div v-if="i.zhi">
                            <p v-for="(ia,inde) in i.children" :key="inde" @click.stop="chakan_liebiao(ia)">
                                <img :src="ia.img" alt=""><span>{{ia.flow_name}}</span>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="yanjing" v-if="moren_dakai==2" @click="moren_dakai=1">
            <div class="youce_box" @click.stop>
                <div class="youce_xiangxi_xinxi" v-for='(item,idx) in xiangxi_xinxi' :key="idx">
					<div class="xiangxi_tit" @click="item.zhi=!item.zhi">
                        <p>{{idx+1}}、详细信息<span class="xiangxi_tit_zuofei" v-if="item.flag_cancel==1">作废单</span></p>
                        <p>
                            <span :style="item.flow_status==4?'color:#67C23A':(item.flow_status==2?'color:#F56C6C':'')" class="zhuangtai">{{zhuangtai_name(item.flow_status)}}</span>
                            <i :class="item.zhi?'el-icon-caret-bottom':'el-icon-caret-left'"></i>
                        </p>
                    </div>
                    
					<transition name="el-zoom-in-top">
						<div v-show="item.zhi" class="transition-box">
							<!-- {{xiangxi_xinxi}} -->
                            
							<p v-if="item.cust_name!==undefined">客户名称：{{item.cust_name}}</p>
							<p v-if="item.file_no!==undefined">归档号：{{item.file_no}}</p>
							<p v-if="item.staff_name!==undefined&&xiangqing.flow_id!='hXstgwxhzh'">负责人：{{item.staff_name}}</p>
							<p v-if="item.dept_name!==undefined&&xiangqing.flow_id!='hXstgwxhzh'">部门：{{item.dept_name}}</p>
							<div v-if="xiangqing.flow_id=='7TvJHPyovM'">
								<ul>
									<li v-for='(i,index) in item.contract_detail' :key="index">
										<p>决策人({{index+1}})</p>
										<p v-if="i.name!==undefined">姓名：{{i.name}}</p>
										<p v-if="i.sex!==undefined">性别：{{i.sex}}</p>
										<p v-if="i.dept!==undefined">部门：{{i.dept}}</p>
										<p v-if="i.post!==undefined">职务：{{i.post}}</p>
										<p v-if="i.fixed_phone!==undefined">座机：{{i.fixed_phone}}</p>
										<p v-if="i.mobile!==undefined">手机：{{i.mobile}}</p>
										<p v-if="i.birthday!==undefined">生日：{{i.birthday}}</p>
										<p v-if="i.interest!==undefined">爱好：{{i.interest}}</p>
										<p v-if="i.qq!==undefined">QQ：{{i.qq}}</p>
										<p v-if="i.wechat!==undefined">微信：{{i.wechat}}</p>
										<p v-if="i.email!==undefined">邮箱：{{i.email}}</p>
										<p v-if="i.card!==undefined">名片：<img @click="tupian_dianji(i.card)" class="tupian_kuang" :src="i.card" alt=""></p>
									</li>
								</ul>
								<p v-if="item.province!==undefined">省份：{{item.province}}</p>
								<p v-if="item.city!==undefined">城市：{{item.city}}</p>
								<p v-if="item.industry!==undefined">行业：{{item.industry}}</p>
								<p v-if="item.source!==undefined">客户来源：{{item.source}}</p>
								<p v-if="item.level!==undefined">客户等级：{{item.level}}</p>
								<p v-if="item.cat_name!==undefined">客户标签：{{item.cat_name}}</p>
								<p v-if="item.biz_addr!==undefined">经营地址：{{item.biz_addr}}</p>
								<p v-if="item.rel_p_com!==undefined">母公司名称：{{item.rel_p_com}}</p>
								<p v-if="item.register_addr!==undefined">注册地址：{{item.register_addr}}</p>
								<p v-if="item.legal_name!==undefined">法定代表人：{{item.legal_name}}</p>
								<p v-if="item.uscc!==undefined">营业执照号：{{item.uscc}}</p>
								<p v-if="item.register_capital!==undefined">注册资本：{{item.register_capital}}</p>
								<p v-if="item.register_date!==undefined">成立日期：{{item.register_date}}</p>
								<p v-if="item.biz_term!==undefined">营业期限：{{item.biz_term}}</p>
								<p v-if="item.biz_scope!==undefined">营业范围：{{item.biz_scope}}</p>
								<p v-if="item.biz_lic_pic!==undefined">营业执照：<img @click="tupian_dianji(item.biz_lic_pic)" class="tupian_kuang" :src="item.biz_lic_pic" alt=""></p>
							</div>
							<div v-if="xiangqing.flow_id=='Z7VDOK9HGu'">
								<p v-if="item.visit_type!==undefined">拜访方式：{{item.visit_type}}</p>
								<p v-if="item.visit_time!==undefined">拜访时间：{{item.visit_time}}</p>
								<p v-if="item.contact!==undefined">决策人：{{item.contact}}</p>
								<p v-if="item.current_stage!==undefined">漏斗现阶段：{{jieduan_hanzi(item.current_stage)}}</p>
								<p v-if="item.next_stage!==undefined">预计下一漏斗阶段：{{jieduan_hanzi(item.next_stage)}}</p>
								<p v-if="item.remarks!==undefined">备注：{{item.remarks}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='453y6pLD52'">
								<p v-if="item.start_time!==undefined">开始时间：{{item.start_time}}</p>
								<p v-if="item.end_time!==undefined">结束时间：{{item.end_time}}</p>
								<p v-if="item.hour_long!==undefined">时长：{{item.hour_long}}小时</p>
								<p v-if="item.current_stage!==undefined">漏斗现阶段：{{jieduan_hanzi(item.current_stage)}}</p>
								<p v-if="item.funn_stage_change_flag!==undefined">漏斗阶段变化：{{item.funn_stage_change_flag}}</p>
								<p v-if="item.funn_stage!==undefined">漏斗达成阶段：{{jieduan_hanzi(item.funn_stage)}}</p>
								<p v-if="item.change_cause!==undefined">阶段变化原因：{{item.change_cause}}</p>
								<p v-if="item.remarks!==undefined">备注：{{item.remarks}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='PLOQ9ku3Ip'">
								<ul>
									<li v-for="(i,index) in item.prod_detail" :key="index">
										<p>产品明细({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
									</li>
								</ul>
								<p v-if="item.stage_funn!==undefined">漏斗阶段：{{jieduan_hanzi(item.stage_funn)}}</p>
								<p v-if="xiangxi_xinxi.stage_before_back!=undefined&&xiangxi_xinxi.flag_back==1">退回前漏斗阶段：{{jieduan_hanzi(xiangxi_xinxi.stage_before_back)}}</p>
								<p v-if="item.amt_funn!==undefined">漏斗金额：{{item.amt_funn}}</p>
								<p v-if="item.date_con_est!==undefined">预计合同日期：{{item.date_con_est}}</p>
								<p v-if="item.rate_funn_disc!==undefined">漏斗折算比例：{{item.rate_funn_disc}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='Eb3n7CKQzi'">
								<p v-if="item.help_order_flag!==undefined">是否为助单行为：{{item.help_order_flag}}</p>
								<p v-if="item.disp_staff_name!==undefined">派工人员：{{item.disp_staff_name}}</p>
								<p v-if="item.help_order_type!==undefined">助单类型：{{item.help_order_type}}</p>
								<p v-if="item.biz_trip_type!==undefined">出差类型：{{item.biz_trip_type}}</p>
								<p v-if="item.addr!==undefined">地址：{{item.addr}}</p>
								<p v-if="item.contact!==undefined">决策人：{{item.contact}}</p>
								<p v-if="item.mobile!==undefined">手机：{{item.mobile}}</p>
								<p v-if="item.trip_start_time!==undefined">开始时间：{{item.trip_start_time}}</p>
								<p v-if="item.trip_end_time!==undefined">结束时间：{{item.trip_end_time}}</p>
								<p v-if="item.hour_long!==undefined">时长：{{item.hour_long}}小时</p>
								<ul>
									<li v-for="(i,index) in item.prod_detail" :key="index">
										<p>产品明细({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
										<p v-if="i.disp_item!==undefined">派工项目：{{i.disp_item}}</p>
									</li>
								</ul>
								<p v-if="item.remarks!==undefined">备注：{{item.remarks}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='qIwxmsMTgv'">
								<p v-if="item.date_con!==undefined">合同日期：{{item.date_con}}</p>
								<p v-if="item.belong_to_organ!==undefined">所属公司：{{item.belong_to_organ}}</p>
								<p v-if="item.industry!==undefined">行业：{{item.industry}}</p>
								<p v-if="item.con_type!==undefined">合同类型：{{item.con_type}}</p>
								<p v-if="item.con_valid_term!==undefined">合同有效时间：{{item.con_valid_term}}</p>
								<ul>
									<li v-for="(i,index) in item.prod_list" :key="index">
										<p>产品明细({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
									</li>
								</ul>
								<p v-if="item.amt_con!==undefined">合同金额：{{item.amt_con}}</p>
								<ul>
									<li v-for="(i,index) in item.amt_list" :key="index">
										<p>开票({{index+1}})</p>
										<p v-if="i.date_inv_est!==undefined">预计发票日期：{{i.date_inv_est}}</p>
										<p v-if="i.amt_inv_est!==undefined">预计发票金额：{{i.amt_inv_est}}</p>
									</li>
								</ul>
								<p v-if="item.amt_con!==undefined">预计发票金额合计：{{item.amt_con}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='VMCizuVOXb'">
								<p v-if="item.belong_to_organ!==undefined">所属公司：{{item.belong_to_organ}}</p>
								<p v-if="item.date_inv!==undefined">发票日期：{{item.date_inv}}</p>
								<p v-if="item.inv_type!==undefined">发票类型：{{item.inv_type}}</p>
								<ul>
									<li v-for="(i,index) in item.prod_list" :key="index">
										<p>发票内容({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
										<p v-if="i.price_unit_no_tax!==undefined">不含税折后单价：{{i.price_unit_no_tax}}</p>
										<p v-if="i.amt_no_tax!==undefined">不含税金额：{{i.amt_no_tax}}</p>
										<p v-if="i.rate_tax!==undefined">税率：{{i.rate_tax}}%</p>
										<p v-if="i.amt_tax!==undefined">税额：{{i.amt_tax}}</p>
										<p v-if="i.amt_inv_detail!==undefined">发票金额：{{i.amt_inv_detail}}</p>
									</li>
								</ul>
								<p v-if="item.amt_no_tax_sum!==undefined">合计不含税金额：{{item.amt_no_tax_sum}}</p>
								<p v-if="item.amt_tax_sum!==undefined">合计税额：{{item.amt_tax_sum}}</p>
								<p v-if="item.amt_inv!==undefined">合计发票金额：{{item.amt_inv}}</p>
								<p v-if="item.amt_inv_sum!==undefined">累计发票金额：{{item.amt_inv_sum}}</p>
								<p v-if="item.amt_inv_un!==undefined">合同未开发票金额：{{item.amt_inv_un}}</p>
								<ul>
									<li v-for="(i,index) in item.amt_list" :key="index">
										<p>回款({{index+1}})</p>
										<p v-if="i.date_rec_est!==undefined">预计回款日期：{{i.date_rec_est}}</p>
										<p v-if="i.amt_rec_est!==undefined">预计回款金额：{{i.amt_rec_est}}</p>
									</li>
								</ul>
								<p v-if="item.cust_name!==undefined">客户：{{item.cust_name}}</p>
								<p v-if="item.tax_no!==undefined">纳税识别号：{{item.tax_no}}</p>
								<p v-if="item.mobile!==undefined">电话：{{item.mobile}}</p>
								<p v-if="item.addr!==undefined">地址：{{item.addr}}</p>
								<p v-if="item.bank!==undefined">开户行：{{item.bank}}</p>
								<p v-if="item.bank_acct!==undefined">开户行账号：{{item.bank_acct}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='He5ln0bdDO'">
								<p v-if="item.date_rec!==undefined">回款日期：{{item.date_rec}}</p>
								<p v-if="item.inv_code!==undefined">发票代码：{{item.inv_code}}</p>
								<p v-if="item.inv_no!==undefined">发票号码：{{item.inv_no}}</p>
								<ul>
									<li v-for="(i,index) in item.prod_detail" :key="index">
										<p>产品回款({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
									</li>
								</ul>
								<p v-if="item.amt_rec!==undefined">回款金额：{{item.amt_rec}}</p>
								<p v-if="item.amt_rec_sum!==undefined">累计回款金额：{{item.amt_rec_sum}}</p>
								<p v-if="item.amt_inv_sum!==undefined">累计开票金额：{{item.amt_inv_sum}}</p>
								<p v-if="item.amt_rec_unrec!==undefined">应收金额：{{item.amt_rec_unrec}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='cvRyWGiUQH'">
								<p v-if="item.start_time!==undefined">开始时间：{{item.start_time}}</p>
								<p v-if="item.end_time!==undefined">结束时间：{{item.end_time}}</p>
								<p v-if="item.hour_long!==undefined">时长：{{item.hour_long}}小时</p>
								<p v-if="item.go_out_cause!==undefined">外出事由：{{item.go_out_cause}}</p>
								<p v-if="item.year_hour_long!==undefined">年累计外出时长：{{item.year_hour_long}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='GBh7N2jMsi'">
								<p v-if="item.remarks!==undefined">出差备注：{{item.remarks}}</p>
								<ul>
									<li v-for="(i,index) in item.trip_detail" :key="index">
										<p>行程({{index+1}})</p>
										<p v-if="i.transport!==undefined">交通工具：{{i.transport}}</p>
										<p v-if="i.leave_city!==undefined">出发城市：{{i.leave_city}}</p>
										<p v-if="i.arrive_city!==undefined">目的城市：{{i.arrive_city}}</p>
										<p v-if="i.start_time!==undefined">开始时间：{{i.start_time}}{{i.start_noon==1?'下午':'上午'}}</p>
										<p v-if="i.end_time!==undefined">结束时间：{{i.end_time}}{{i.end_noon==1?'下午':'上午'}}</p>
										<p v-if="i.hour_long!==undefined">时长（天）：{{i.hour_long}}</p>
									</li>
								</ul>
								<p v-if="item.trip_days!==undefined">出差天数：{{item.trip_days}}</p>
								<p v-if="item.year_trip_days!==undefined">年累计出差天数：{{item.year_trip_days}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='H49d4edc3T'">
								<p v-if="item.date_deliver!==undefined">日期：{{item.date_deliver}}</p>
								<ul>
									<li v-for="(i,index) in item.prod_detail" :key="index">
										<p>产品明细({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
									</li>
								</ul>
								<p v-if="item.amt_deliver!==undefined">交付金额：{{item.amt_deliver}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='hXstgwxhzh'">
                                <p v-if="item.staff_name!==undefined">出库员：{{item.staff_name}}</p>
								<p v-if="item.dept_name!==undefined">部门：{{item.dept_name}}</p>
								<p v-if="item.cust_name!==undefined">客户：{{item.cust_name}}</p>
								<p v-if="item.file_no!==undefined">归档号：{{item.file_no}}</p>
								<p v-if="item.date_out_store!==undefined">出库日期：{{item.date_out_store}}</p>
								<ul>
									<li v-for='(i,index) in item.prod_list' :key="index">
										<p>列表({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.site_name!==undefined">仓库：{{i.site_name}}</p>
										<p v-if="i.site_no!==undefined">货位号：{{i.site_no}}</p>
										<p v-if="i.supply_name!==undefined">供应商：{{i.supply_name}}</p>
										<p v-if="i.price_unit!==undefined">产品单价：{{i.price_unit}}</p>
										<p v-if="i.qty_out_store!==undefined">出库数量：{{i.qty_out_store}}</p>
										<p v-if="i.amt_out_store!==undefined">出库金额：{{i.amt_out_store}}</p>
									</li>
								</ul>
								<p v-if="item.qty_out_store_sum!==undefined">出库数量合计：{{item.qty_out_store_sum}}</p>
								<p v-if="item.amt_out_store_sum!==undefined">出库金额合计：{{item.amt_out_store_sum}}</p>
							</div>
							<!-- <ul>
								<li v-for="(i,index) in xiangqing.extra" :key="index">
									<p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p>
								</li>
							</ul> -->
                            <ul>
								<li v-for="(i,index) in item.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
										<p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(it,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(it)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{it.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
										<p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(it)" v-for="(it,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{it.flow_desc}}:{{it.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(it,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in it.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'&&ite.img_url!=undefined&&ite.img_url.length!=0">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
						</div>
					</transition>
				</div>
            </div>
        </div>
		<tupian ref="bigphote"></tupian>
    </div>
</template>

<script>
import { query_eye_form_list, query_form_list_file_nos, query_customer_file_list, query_flow_info_list } from '../../api/api.js'
import BiaodanXiangqing from '../../components/biaodan/biaodanxq'
import tupian from '../../components/bigphote/bigphote'
export default {
  name: 'yanjing',
  data () {
    return {
      title: [],
      guanbi: false,
      moren_dakai: '0',
      fuqin_dedao_kehu: '',
      kehu_guidang_hao_list: [],
            
      xiangxi_xinxi: [],
      xiangqing: '',
      xuan_num: '0'
    }
  },
  mounted () {
  },
  created () {
  },
  components: { BiaodanXiangqing, tupian },
  methods: {
    jichu () {
      query_customer_file_list({
        data: {
          ent_id: this.$ent_id(),
          cust_id: this.fuqin_dedao_kehu.cust_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const data = JSON.parse(res.data.body.data)
          console.log(data)
          const list = []
          data.forEach(item => {
            if (item.file_no != undefined) {
              list.push(item)
              this.$set(item, 'zhi', false)
            }
          })
          this.kehu_guidang_hao_list = list
        } else if (res.data.code == 500) {}
      })
      // query_flow_info_list({data:{
      //     ent_id:this.$ent_id(),
      // }}).then(res=>{
      //     console.log(res)
      //     if(res.data.code==200){
      //         let date=JSON.parse(res.data.body.data)
      //         console.log(date)
      //         date.forEach(item=>{
      //             this.$set(item,'name',item.flow_group_name)
      //             item.children.forEach(it=>{
      //                 if(it.flow_id=='7TvJHPyovM'){
      //                     this.$set(it,'img',require('../../assets/biaodan/dan_kehu.gif'))
      //                 }else if(it.flow_id=='Z7VDOK9HGu'){
      //                     this.$set(it,'img',require('../../assets/biaodan/dan_fangke.gif'))
      //                 }else if(it.flow_id=='453y6pLD52'){
      //                     this.$set(it,'img',require('../../assets/biaodan/dan_baifang.gif'))
      //                 }else if(it.flow_id=='PLOQ9ku3Ip'){
      //                     this.$set(it,'img',require('../../assets/biaodan/dan_loudou.gif'))
      //                 }else if(it.flow_id=='Eb3n7CKQzi'){
      //                     this.$set(it,'img',require('../../assets/biaodan/dan_paigong.gif'))
      //                 }else if(it.flow_id=='qIwxmsMTgv'){
      //                     this.$set(it,'img',require('../../assets/biaodan/dan_hetong.gif'))
      //                 }else if(it.flow_id=='VMCizuVOXb'){
      //                     this.$set(it,'img',require('../../assets/biaodan/dan_fapiao.gif'))
      //                 }else if(it.flow_id=='He5ln0bdDO'){
      //                     this.$set(it,'img',require('../../assets/biaodan/dan_huikuan.gif'))
      //                 }else   if(it.flow_id=='cvRyWGiUQH'){
      //                     this.$set(it,'img',require('../../assets/biaodan/dan_waichu.gif'))
      //                 }else  if(it.flow_id=='GBh7N2jMsi'){
      //                     this.$set(it,'img',require('../../assets/biaodan/dan_chuchai.gif'))
      //                 }else if(it.flow_id=='H49d4edc3T'){
      //                     this.$set(it,'img',require('../../assets/biaodan/dan_jiaofu.gif'))
      //                 }
      //             })
      //         })
      //         this.title=date
      //     }else if(res.data.code==500){}
      // })
    },
    dianji_dakai (i) {
      this.guanbi = true
      console.log(i)
      this.fuqin_dedao_kehu = i
      this.jichu()
    },
    // 点击查看列表
    chakan_liebiao (i) {
      console.log(i)
      console.log(this.kehu_guidang_hao_list)
      this.xiangqing = i
      // let list=[]
      // for(let a=0;a<this.kehu_guidang_hao_list.length;a++){
      //     if(this.kehu_guidang_hao_list[a].zhi){
      //         list.push(this.kehu_guidang_hao_list[a].file_no)
      //     }
      // }
      query_form_list_file_nos({
        data: {
          ent_id: this.$ent_id(),
          file_no: this.kehu_guidang_hao_list[this.xuan_num].file_no,
          flow_id: i.flow_id,
          cust_id: this.fuqin_dedao_kehu.cust_id,
          flow_status: '4'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.moren_dakai = 2
          if (date != undefined) {
            date.forEach(item => {
              this.$set(item, 'zhi', false)
              this.$set(item, 'zidingyi', item.extra != undefined ? JSON.parse(item.extra) : [])
            })
          }
          this.xiangxi_xinxi = date
        } else if (res.data.code == 500) {}
      })
    },
    zhuangtai_name (i) {
      if (i == 0) {
        return '发起审批'
      } else if (i == 1) {
        return '审批中'
      } else if (i == 2) {
        return '已拒绝'
      } else if (i == 3) {
        return '已退回'
      } else if (i == 4) {
        return '审批通过'
      } else if (i == 5) {
        return '已归档'
      } else if (i == 6) {
        return '已转交'
      } else if (i == 7) {
        return '已撤销'
      } else if (i == 8) {
        return '已抄送'
      }
    },
    dianji_dakai_liebioao () {
      // let list=[]
      // this.kehu_guidang_hao_list.forEach(item=>{
      //     if(item.zhi){
      //         list.push(item)
      //     }
      // })
      if (this.kehu_guidang_hao_list.length != 0) {
        this.moren_dakai = 1
        query_eye_form_list({
          data: {
            ent_id: this.$ent_id(),
            cust_id: this.fuqin_dedao_kehu.cust_id,
            // file_nos:list.map(it=>it.file_no).join(',')
            file_nos: this.kehu_guidang_hao_list[this.xuan_num].file_no
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(item => {
              this.$set(item, 'name', item.flow_group_name)
              item.children.forEach(it => {
                if (it.flow_id == '7TvJHPyovM') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_kehu.gif'))
                } else if (it.flow_id == 'Z7VDOK9HGu') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_fangke.gif'))
                } else if (it.flow_id == '453y6pLD52') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_baifang.gif'))
                } else if (it.flow_id == 'PLOQ9ku3Ip') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_loudou.gif'))
                } else if (it.flow_id == 'Eb3n7CKQzi') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_paigong.gif'))
                } else if (it.flow_id == 'qIwxmsMTgv') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_hetong.gif'))
                } else if (it.flow_id == 'VMCizuVOXb') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_fapiao.gif'))
                } else if (it.flow_id == 'He5ln0bdDO') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_huikuan.gif'))
                } else if (it.flow_id == 'cvRyWGiUQH') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_waichu.gif'))
                } else if (it.flow_id == 'GBh7N2jMsi') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_chuchai.gif'))
                } else if (it.flow_id == 'H49d4edc3T') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_jiaofu.gif'))
                } else if (it.flow_id == '5FotYgnu0Z') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_jiaofu.gif'))
                } else if (it.flow_id == 'Xsk2pLBS9a') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_paigong.gif'))
                } else if (it.flow_id == 'u1SCMMoUwK') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_fapiao.gif'))
                } else if (it.flow_id == 'vY9ubhTO89') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_huikuan.gif'))
                } else if (it.flow_id == 'fZ0x5qedzn') {
                  this.$set(it, 'img', require('../../assets/biaodan/dan_hetong.gif'))
                } else if (it.flow_id == 'hXstgwxhzh') { // 出库单
                  this.$set(it, 'img', require('../../assets/biaodan/dan_waichu.gif'))
                } else {
                  this.$set(it, 'img', it.logo_url)
                }
              })
            })
            for (let i = 0; i < date.length; i++) {
              if (date[i].flow_group_id == 'stop') {
                date.splice(i, 1)
              }
            }
            date.forEach(item => {
              this.title.forEach(ite => {
                if (item.flow_group_id == ite.flow_group_id && ite.zhi != undefined) {
                  this.$set(item, 'zhi', ite.zhi)
                }
              })
            })
            function zhi () {
              if (date.length > 3) {
                return false
              } else {
                return true
              }
            }
            date.forEach(item => {
              if (item.zhi == undefined) {
                this.$set(item, 'zhi', zhi)
              }
            })
            this.title = date
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '请选择您要查看的归档号',
          type: 'warning'
        })
      }
    },
    dianji_xiazai_fujian (i) {
      this.$func.fujian_xiazai(i)
    },
    jieduan_hanzi (i) {
      if (i == '1') {
        return '初步接触'
      } else if (i == '2') {
        return '需求确认'
      } else if (i == '3') {
        return '报价及方案展示'
      } else if (i == '4') {
        return '商务谈判'
      } else if (i == '5') {
        return '赢单'
      } else if (i == '6') {
        return '输单'
      }
    },
    // 点击表单详情
    biaodan_xiangqing (i) {
      this.$refs.biaodan_xiangqing_zhanshi[0].jichu(i)
    },
    tupian_dianji (img) {
      this.$refs.bigphote.jichu(img)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.yanjing {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    z-index:101;
    background: rgba(0,0,0,0.5);
    .box{
        height:5.8rem;
        width:5.9rem;
        background:#fff;
        position: relative;
        top:1.28rem;
        left:50%;
        margin-left: -2.95rem;
        border-radius: 0.16rem;
        padding: 0 0.5rem;
        overflow-y:auto;
        &::-webkit-scrollbar {
            width: 0.2rem;
            height: 0.08rem;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 0.12rem;
            border: 0.06rem solid rgba(0, 0, 0, 0);
            box-shadow: 0.08rem 0 0 #A5ADB7 inset;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            box-shadow: 0.08rem 0 0 #4A4A4A inset;
        }
        .el-icon-close{
            position: absolute;
            right: -0.13rem;
            top: -0.13rem;
            height:0.3rem;
            width:0.3rem;
            border-radius: 50%;
            text-align: center;
            color:#fff;
            line-height: 0.3rem;
            background: #fc6a6a;
            cursor: pointer;
        }
        .box_tit{
            font-size: 0.22rem;
            color:#1a2533;
            text-align: center;
            padding: 0.5rem 0;
        }
        .ul{
            padding-bottom: 0.3rem;
            li{
                padding: 0 0.25rem;
                position: relative;
                color:#1a2533;
                font-size: 0.18rem;
                .changdu{
                    color:#888888;
                    font-size: 0.16rem;
                }
                div{
                    display: flex;
                    flex-wrap:wrap;
                    p{
                        border:0.01rem solid #ccc;
                        width:1.9rem;
                        height:0.7rem;
                        background:#ffffff;
                        display: flex;
                        align-items: center;
                        margin:0.2rem 0.34rem;
                        cursor: pointer;
                        font-size: 0.14rem;
                        border-radius: 0.03rem;
                        img{
                            margin-right:0.26rem;
                            margin-left:0.1rem;
                            width:0.38rem;
                            height:0.38rem;
                            border-radius: 0.04rem;
                        }
                        &:hover{
                            border:0.01rem solid #2365b5;
                        }
                    }
                }
                .zhankai{
                    position: absolute;
                    bottom: 0.2rem;
                    right:0.3rem;
                    cursor: pointer;
                    font-size: 0.14rem;
                    color:#888888;
                }
            }
        }
        .kehu_ul{
            height:3.8rem;
            overflow-y:auto;
            &::-webkit-scrollbar {
                width: 0.2rem;
                height: 0.08rem;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }
            
            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
            li{
                padding: 0.1rem 0.2rem;
                width:40%;
                height:0.2rem;
                float: left;
                display: flex;
                align-items: center;
                cursor: pointer;
                &:hover{
                    background: #d9dadd;
                }
                img{
                    height:0.16rem;
                    width:0.16rem;
                }
            }
        }
        .kehu_btn{
            width:0.8rem;
            height:0.3rem;
            font-size: 0.14rem;
            margin: 0.1rem auto;
            line-height: 0.3rem;
            text-align: center;
            background: rgb(141, 81, 228);
            border-radius: 0.04rem;
            color:#fff;
            cursor: pointer;
        }
    }
    .youce_box{
        position: absolute;
        right:0;
        top:0;
        width:5.8rem;
        padding: 0.32rem 0.2rem 0 0.2rem;
        box-shadow:-0.06rem 0 0.04rem 0 rgba(0,0,0,0.1);
        background:#fff;
        min-height: 96vh;
        .youce_xiangxi_xinxi{
            .xiangxi_tit{
                height:0.39rem;
                padding: 0 0.32rem;
                background: #f4f7fd;
                line-height: 0.6rem;
                font-size: 0.14rem;
                color:#1A2533;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                img{
                    width:100%;
                }
                .xiangxi_tit_zuofei{
                    font-size: 0.12rem;
                    color:red;
                    border:1px solid red;
                    padding: 0 0.1rem;
                    margin-left: 0.2rem;
                }
            }
            .transition-box{
                padding: 0.2rem 0;
                background: #f4f7fd;
                p{
                    font-size: 0.12rem;
                    color:#888888;
                    padding: 0.05rem 0.2rem;
                    span{
                        font-size: 0.12rem;
                        color:#4C4A4D;
                    }
                }
                ul{
                    li{
                        border:0.01rem solid #ccc;
                        border-left: none;
                        border-right: none;
                    }
                }
                position: relative;
                .zhuangtai{
                    position: absolute;
                    font-size: 0.2rem;
                    right:0.1rem;
                    // top:0.4rem;
                }
                .keyi_dianji{
                    cursor: pointer;
                    .fujian_xiazai_kuang,.guanlian_kuang{
                        padding: 0.1rem 0.35rem;
                        background: #c8c2c2;
                        color:#1A2533;
                        i{
                            font-size: 0.2rem;
                        }
                    }
                }
                .tupian_kuang{
                    width:30%;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
